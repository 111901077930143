module.exports = {
    url: {
        api: `https://api.monitoringtoolkit.nl`,
        main: `http://app.monitoringtoolkit.nl`,
        login: `http://app.monitoringtoolkit.nl/inloggen`,
        home: `http://app.monitoringtoolkit.nl/`,
        cdn: `http://cdn.monitoringtoolkit.nl/`,
    },
    regex: {
        name: /^[a-zA-Z -]{2,50}$/,
        mail: /^.+@.+$/,
        phonenumber: /^\+?[1-9]\d{3,14}$/,
        password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&+\-#^])[A-Za-z\d@$!%*?&+\-#^]{8,64}$/,
    },
    cookiedomain: "monitoringtoolkit.nl",
    allowedredirectdomains: [
        'monitoringtoolkit.nl',
        'localhost',
        'localhost:6020',
        'localhost:8080'
    ],
    dateformat: {
        weekday: 'short',
        year: 'numeric',
        day: 'numeric',
        month: 'long',
        hour: 'numeric',
        minute: 'numeric'
    }
}